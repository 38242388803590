import { Button, Paper, Typography } from "@mui/material"
import { marginBottomStyle, paddingStyle } from '../dashboardStyles'

const Success = ({ model, onClose }) => {
  return (
    <>
      <Paper sx={{ ...paddingStyle, ...marginBottomStyle }}>
        <Typography variant='h6'>Congratulations! </Typography>
        <Typography sx={marginBottomStyle} variant='body1' color='secondary'>Your file{model.docs.length > 1 ? '\'s have' : ' has'} been sent successfully.</Typography>
      </Paper>
      <Button onClick={onClose} variant='contained' color='primary'>Return Home</Button>
    </>
  )
}

export default Success
