import { Add } from "@mui/icons-material"
import { Button, Grid} from "@mui/material"
import FileRequestLineItem from "../components/FileRequestLineItem"
import {Link as RouterLink} from 'react-router-dom'
import { marginBottomStyle as marginBottom } from "./dashboardStyles"
import BaseCard from "../components/BaseCard"
import useBaseApi from "hooks/useBaseApi"
import { useEffect, useState } from "react"

const maxHeight = {

}

const main = {
  ...maxHeight,
  paddingBottom: theme => theme.spacing(2),
}

const gridItem = {
  ...maxHeight,
}


const DashHome = () => {
  const api = useBaseApi()
  const [ inbox, setInbox ] = useState({
    received: [],
    sent: [],
  })
  useEffect(() => {
    api.files().getAll()
      .then(files => {
        setInbox(files)
      })
  }, [api])
  return (
    <>
      <Grid sx={main} container spacing={4} justifyContent='center'>
        <Grid item xs={12} sm={12} md={6} sx={maxHeight}>
          <BaseCard title='Inbox'>
            {inbox.received.map(file => (
              <FileRequestLineItem
                {...file}
                onFileAction={pk => {
                  setInbox(inbox => {
                    return {
                      ...inbox,
                      received: inbox.received.filter(file => file.pk !== pk),
                    }
                  })
                }}
              />
            ))}
          </BaseCard>
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={gridItem}>
          <Button component={RouterLink} to='/dashboard/new-document' fullWidth sx={marginBottom} startIcon={<Add />} variant='contained' color='primary'>New Document</Button>
          <BaseCard title='Sent'>
            {inbox.sent.map(file => (
              <FileRequestLineItem
                isSender
                {...file}
              />
            ))}
          </BaseCard>
        </Grid>
      </Grid>
    </>
  )
}

export default DashHome
