import { Business, Email, Person, Phone } from "@mui/icons-material"
import ListDetailView from "components/ListDetailView"

const contactDetails = [
  {
    Icon: Person,
    field: 'name',
    label: 'User',
  },
  {
    Icon: Business,
    field: 'organization',
    label: 'Organization',
    disabled: true,
  },
  {
    Icon: Email,
    field: 'email',
    label: 'Email',
    disabled: true,
  },
  {
    Icon: Phone,
    field: 'tel',
    label: 'Phone Number',
  },
]


const ContactDetail = ({contact, editing, onChange}) => {
  return (
    <ListDetailView
      value={contact}
      fields={contactDetails}
      editing={editing}
      onChange={onChange}
    />
  )
}

export default ContactDetail
