export const bottomMargin = {
  marginBottom: theme => theme.spacing(1)
}

export const doubleBottomMargin = {
  marginBottom: theme => theme.spacing(2)
}

export const button = {
  ...bottomMargin,
  flex: 1,
  justifyContent: 'flex-start',
  textTransform: 'none',
}
