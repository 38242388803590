import { AccountBalance, Business, Phone, Place } from "@mui/icons-material"
import { AppBar, Button, Container, Dialog, Toolbar } from '@mui/material'
import ListDetailView from "components/ListDetailView"
import PlanSelection from "components/PlanSelection"
import { useCallback, useMemo, useState } from "react"

import { useLocation } from 'react-router-dom'



const OrganizationDetail = ({organization, editing, onChange }) => {
  const l = useLocation()
  const searchParams = new URLSearchParams(l.search)
  const [ pickPlan, setPickPlan ] = useState(searchParams.get('upgrade'))
  const onPickPlanClose = useCallback(() => setPickPlan(false), [])
  const onPickPlan = useCallback(() => setPickPlan(true), [])
  const orgDetails = useMemo(() => [
    {
      Icon: Business,
      field: 'organization',
      label: 'Organization',
      disabled: true,
    },
    {
      Icon: Phone,
      field: 'tel',
      label: 'Phone Number',
    },
    {
      Icon: Place,
      field: 'address',
      label: 'Address',
    },
    {
      Icon: AccountBalance,
      field: 'plan',
      label: 'Current Plan',
      onClick: onPickPlan,
    },
  ], [onPickPlan])

  return (
    <>
      <ListDetailView
        value={organization}
        fields={orgDetails}
        editing={editing}
        onChange={onChange}
      />
      <Dialog fullScreen open={pickPlan}>
        <AppBar>
          <Toolbar>
            <Button onClick={onPickPlanClose} color='secondary' variant='contained'>Back</Button>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Container>
          <PlanSelection
            currentPlanId={organization.planId}
            paddleId={organization.paddleId}
            organizationId={organization.organization}
            email={organization.admin}
          />
        </Container>
      </Dialog>
    </>
  )
}

export default OrganizationDetail
