import useEditable from './useEditable'
import useBaseApi from './useBaseApi'
import { useEffect } from 'react'

const useOrganization = (organization, refresh = 0) => {
  const [orgDetails, orgDispatch] = useEditable({
    members: [],
    pk: '',
    seats: [],
    subscription: {},
  })

  const api = useBaseApi()

  useEffect(() => {
    if (!organization)
      return
    api
      .organizations(organization)
      .get({ refresh })
      .then(org => {
        if (!org.error)
          orgDispatch({
            type: 'set',
            payload: org,
          })
      })
  }, [api, organization, orgDispatch, refresh])

  return [orgDetails, orgDispatch]
}

export default useOrganization
