import commonEmails from 'data/common-emails.json'

export const cleanEmail = email => {
  return email.toLowerCase().trim()
}

// eslint-disable-next-line no-control-regex
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const validEmail = email => {
  return !email.includes('#') && emailRegex.test(email)
}

export const invalidEmail = email => {
  const m = cleanEmail(email)
  if (!validEmail(m))
    return 'Email is not formatted properly. Please make sure you entered it correctly.'
  // if (commonEmails.some(domain => m.includes(domain)))
  //   return 'You will need to reach out to support for this domain'
  return false
}

export const commonDomain = email => {
  return commonEmails.some(domain => email.includes(domain))
}
