import { Button, Link, Grid, Typography } from "@mui/material"
import YesNoSelection from "components/YesNoSelection"
import { useState } from "react"
import { marginBottomStyle } from '../dashboardStyles'
import FileOverview from "./FileOverview"


const AdditionalDocuments = ({ model, dispatch }) => {
  const [buttonState, setButtonState] = useState({
    addAnother: '',
    copySettings: '',
  })
  const buttonHandler = field => value => setButtonState(d => ({ ...d, [field]: value }))
  const onNextClick = () => {
    if (!buttonState.addAnother)
      return dispatch({ type: 'next' })
    return dispatch({
      type: 'addAnotherFile',
      copy: buttonState.copySettings && lastFile,
    })
  }
  const lastFile = (() => {
    if (model.docs.length === 0) return null
    return model.docs[model.docs.length - 1]
  })()
  return (
    <>
      {model.docs.map((file, fileIndex) => (
        <FileOverview
          file={file}
          onRemoveClick={() => {
            dispatch({ type: 'removeFile', removeAtIndex: fileIndex })
          }}
        >
          <Link underline='always' color='secondary' onClick={() => dispatch({ type: 'editFile', payload: fileIndex })}>edit details</Link>
        </FileOverview>
      ))}
      <Grid container spacing={2} alignItems='center' sx={marginBottomStyle}>
        <Grid item xs={6}><Typography align='right' variant='body1' color='secondary'>Would you like to add another document?</Typography></Grid>
        <Grid item xs={6}>
          <YesNoSelection
            value={buttonState.addAnother}
            onChange={buttonHandler('addAnother')}
          />
        </Grid>
        <Grid sx={{ visibility: buttonState.addAnother && lastFile ? 'visible' : 'hidden' }} item xs={6}><Typography align='right' variant='body1' color='secondary'>Repeat {lastFile?.file?.name}'s details?</Typography></Grid>
        <Grid sx={{ visibility: buttonState.addAnother && lastFile ? 'visible' : 'hidden' }} item xs={6}>
          <YesNoSelection
            value={buttonState.copySettings}
            onChange={buttonHandler('copySettings')}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-end' spacing={2}>
        <Grid item xs={6}>
          <Button fullWidth onClick={onNextClick} variant='contained' color='primary'>
            {buttonState.addAnother ? 'Add Another File' : 'Proceed to Confirmation'}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default AdditionalDocuments
