import { Button, Box } from "@mui/material"

const NextButton = ({ dispatch, disabled, children }) => (
  <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
    <Button variant='contained' color='primary' disabled={disabled} onClick={() => dispatch({ type: 'next' })}>
      {children}
    </Button>
  </Box>
)

export default NextButton
