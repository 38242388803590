import { Box } from "@mui/material"
import { useCallback } from "react"
import { useLocation } from "react-router"
import ContactsExternal from "../ContactsExternal"
import NextButton from "./NextButton"


const SetRecipient = ({ model, dispatch }) => {
  const onSelect = useCallback(recipient => dispatch({
    type: 'changeRecipient',
    payload: { ...recipient },
  }), [dispatch])
  const queryParams = useLocation().search
  const initialEmail = new URLSearchParams(queryParams).get('email') ?? undefined
  return (
    <>
      <Box>
        <ContactsExternal
          currentSelectedContact={model.recipient}
          onContactSelected={onSelect}
          initialContactEmail={initialEmail}
        />
      </Box>
      <NextButton
        dispatch={dispatch}
        disabled={!model.recipient.email}
      >
        Continue to Upload
      </NextButton>
    </>
  )
}

export default SetRecipient
