import { Card, CardContent, Stack } from "@mui/material"
import {Switch, Route} from 'react-router-dom'
import TopBar from "../components/TopBar"
import Login from "./Login"
import CreateAccount from './CreateAccount'
import VerifyAccount from "./VerifyAccount"

const stackStyle = {
  paddingTop: '72px',
  minHeight: '100vh',
}

const cardStyle = {
  maxWidth: 492,
}

const Home = () => {
  return (
    <>
      <TopBar />
      <Stack sx={stackStyle} alignItems='center' justifyContent='center'>
        <Card sx={cardStyle}>
          <CardContent>
            <Switch>
              <Route path='/verify-account/:token'>
                <VerifyAccount />
              </Route>
              <Route path='/create-account'>
                <CreateAccount />
              </Route>
              <Route path='/'>
                <Login />
              </Route>
            </Switch>
          </CardContent>
        </Card>
      </Stack>
    </>
  )
}

export default Home
