import { AES as Encrypter, enc as Encodings, SHA512, lib } from 'crypto-js'

export const hash = toHash => SHA512(toHash).toString(Encodings.Base64)
export const hashPassword = (password) => {
  const intermediate = hash(password)
  return intermediate + hash(intermediate + password)
}

const createPasswordKey = ({password, salt}) => {
  return hashPassword(
    hashPassword(password) + hashPassword(salt)) +
    hashPassword(hashPassword(password + salt) + salt + password
  )
}

const getCrypto = () => {
  const crypto = window.crypto
  if (!crypto)
    throw new Error('This browser does not support securely generating keys and randoms. Please use a different browser to securely transfer documents')
  return crypto
}

const rsaKeyGenerator = async () => {
  const crypto = getCrypto()
  const key = await crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256',
    },
    true,
    ['encrypt', 'decrypt'],
  )
  return key
}

const keyExportType = 'jwk'

const rsaKeyExporter = async key => {
  const crypto = getCrypto()
  const jwk = await crypto.subtle.exportKey(keyExportType, key)
  return JSON.stringify(jwk)
}

const rsaKeyImporter = async (storedData, toEncrypt = false) => {
  const crypto = getCrypto()
  const key = await crypto.subtle.importKey(
    keyExportType,
    JSON.parse(storedData),
    {name: 'RSA-OAEP', hash: 'SHA-256'},
    true,
    [ toEncrypt ? 'encrypt' : 'decrypt' ]
  )
  return key
}

function convertWordArrayToUint8Array(wordArray) {
  const arrayOfWords = wordArray.hasOwnProperty("words") ? wordArray.words : [];
  const length = wordArray.hasOwnProperty("sigBytes") ? wordArray.sigBytes : arrayOfWords.length * 4;
  const uInt8Array = new Uint8Array(length)
  let index = 0
  for (let i = 0; i < length; i++) {
    let word = arrayOfWords[i];
    uInt8Array[index++] = word >> 24;
    uInt8Array[index++] = (word >> 16) & 0xff;
    uInt8Array[index++] = (word >> 8) & 0xff;
    uInt8Array[index++] = word & 0xff;
  }
  return uInt8Array;
}

const encryptDocument = ({fileContents, secret, ignoreConversion = false }) => Encrypter.encrypt(ignoreConversion ? fileContents : lib.WordArray.create(fileContents), secret).toString()
const decryptDocument = ({fileContents, secret}) => {
  return Encrypter.decrypt(fileContents, secret)
}

const getDocumentKey = async ({key, encryptedSecret}) => {
  const crypto = getCrypto()
  const encoded = await  crypto.subtle.decrypt(
    {
      name: 'RSA-OAEP',
    },
    key,
    Buffer.from(encryptedSecret, 'base64'),
  )
  const decoder = new TextDecoder()
  return decoder.decode(encoded)
}

export const browserCompatibleForEncryption = () => {
  try {
    getCrypto()
  } catch(e) {
    return false
  }
  return true
}

export const createKeys = async ({ password, salt }) => {
  const { publicKey, privateKey } = await rsaKeyGenerator()
  const [publicJwk, privateJwk] = await Promise.all([publicKey, privateKey].map(rsaKeyExporter))
  const passwordKey = createPasswordKey({ password, salt })
  const encryptedPrivateJwk = encryptDocument({
    fileContents: privateJwk,
    secret: passwordKey,
    ignoreConversion: true,
  })
  return {
    publicJwk,
    encryptedPrivateJwk,
  }
}

export const decryptPrivateKey = async ({ password, salt, encryptedPrivateJwk }) => {
  const passwordKey = createPasswordKey({ password, salt })
  const wordArr = decryptDocument({
    fileContents: encryptedPrivateJwk,
    secret: passwordKey
  })
  const jwk = wordArr.toString(Encodings.Utf8)
  return rsaKeyImporter(jwk, false)
}

export const decryptDocumentWithSecret = async ({
  fileContents,
  encryptedSecret,
  key,
}) => {
  const secret = await getDocumentKey({key, encryptedSecret})
  const wordArr = await decryptDocument({ fileContents, secret })
  return convertWordArrayToUint8Array(wordArr)
}

export const encryptDocumentWithSecret = async ({
  fileContents,
  encryptedSecret,
  key,
}) => {
  const secret = await getDocumentKey({ key, encryptedSecret })
  return encryptDocument({fileContents, secret})
}

const randomGenerator = async () => {
  const crypto = getCrypto()
  const key = await crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt']
  )
  const exportedKey = await crypto.subtle.exportKey('raw', key)
  return Buffer.from(exportedKey).toString('base64')
}

export const createEncryptedSecret = async ({
  senderPublicJwk,
  recipientPublicJwk,
}) => {
  const crypto = getCrypto()
  const secret = await randomGenerator()
  const [ sender, recipient ] = await Promise.all([senderPublicJwk, recipientPublicJwk].map(async jwk => {
    const key = await rsaKeyImporter(jwk, true)
    const encoder = new TextEncoder()
    const arrBuffer = await crypto.subtle.encrypt(
      {
        name: "RSA-OAEP"
      },
      key,
      encoder.encode(secret),
    )
    return Buffer.from(arrBuffer).toString('base64')
  }))
  return {
    sender,
    recipient,
    secret,
  }
}
