import { Button, Grid, TextField } from "@mui/material"
import {useState} from 'react'
import useTextFieldHandler from 'hooks/useTextFieldHandler'

import {Link as RouterLink, Redirect } from 'react-router-dom'

import {validEmail, cleanEmail} from 'utilities/email'

import { bottomMargin, doubleBottomMargin, button } from './styles'
import { hashPassword } from 'utilities/encrypter'
import useBaseApi from 'hooks/useBaseApi'


const Login = () => {
  const [ loginData, setLoginData ] = useState({
    email: '',
    password: '',
  })
  const [ loading, setLoading ] = useState(false)
  const loginHandler = useTextFieldHandler(setLoginData)

  const invalidEmail = loginData.email !== '' && !validEmail(cleanEmail(loginData.email))
  const disabledSubmit = loading || invalidEmail || ['email', 'password'].some(a => loginData[a] === '')
  const api = useBaseApi()
  const onFormSubmit = async (e) => {
    e.preventDefault()
    if (disabledSubmit)
      return
    setLoading(true)
    const passwordHash = hashPassword(loginData.password)
    const { user: { salt, encryptedPrivateJwk, token } = {}, error, message } = await api.users().login({
      email: loginData.email,
      passwordHash,
    })
    setLoading(false)
    if (error)
      return alert(message)
    await api.token.login({token, password: loginData.password, salt, encryptedPrivateJwk })
  }
  if (api.loggedIn())
    return <Redirect to='/dashboard' />
  return (
    <>
      <form onSubmit={onFormSubmit}>
        <TextField sx={bottomMargin} label='Email' fullWidth name='email' value={loginData.email} onChange={loginHandler} error={invalidEmail} />
        <TextField sx={doubleBottomMargin} label='Password' type='password' fullWidth name='password' value={loginData.password} onChange={loginHandler} />
        <Button type='submit' disabled={disabledSubmit} size='large' fullWidth sx={button} color='primary' variant='contained'>
          Log in
        </Button>
      </form>
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <Button sx={button} component={RouterLink} to='/create-account'>Create New Account</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default Login
