import { Edit, Save, Cancel } from "@mui/icons-material"
import { useMemo } from "react"

import ActionCard from './ActionCard'

const EditCard = ({ title, children, onEdit, editing, onSave, onCancel, disabled }) => {
  const actions = useMemo(() => {
    if (!editing)
      return [{
        Icon: Edit,
        action: onEdit,
        disabled,
      }]
    return [
      {
        Icon: Cancel,
        action: onCancel,
        disabled,
      },
      {
        Icon: Save,
        action: onSave,
        disabled,
      },
    ]
  }, [ editing, onEdit, onCancel, onSave, disabled ])
  return (
    <ActionCard
      title={title}
      actions={actions}
    >
      {children}
    </ActionCard>
  )
}

export default EditCard
