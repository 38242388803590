export const plans = {
  prestige: '758448',
  basic: '770180',
  freemium: process.env.REACT_APP_FREEMIUM_PLAN,
}

export const getPlan = planId => {
  if (!planId)
    return {
      name: 'Freemium',
      planId: plans.freemium,
    }
  const plan = `${planId}`
  if (plan.toLowerCase().includes('beta'))
    return {
      name: 'Beta',
      planId,
    }
  switch (plan) {
    case plans.prestige:
      return {
        name: 'Prestige',
        planId: plans.prestige,
      }
    case plans.basic:
      return {
        name: 'Basic',
        planId: plans.basic,
      }
    case plans.freemium:
    default:
      return {
        name: 'Freemium',
        planId: plans.freemium,
      }
  }
}

export const getPlanId = plan => {
  switch (plan) {
    case 'Prestige':
      return plans.prestige
    case 'Basic':
      return plans.basic
    case 'Freemium':
      return plans.freemium
    default:
      return plans.freemium
  }
}

export const getPlanSeats = planId => {
  if (!planId)
    return 1
  const plan = `${planId}`
  if (plan.toLowerCase().includes('beta'))
    return 3
  switch (plan) {
    case plans.prestige:
      return 10
    case plans.basic:
      return 5
    default:
      return 1
  }
}

export default getPlan
