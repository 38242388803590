import { PermContactCalendar, PersonAdd, PersonRemove } from "@mui/icons-material"
import { CircularProgress, Divider, IconButton, InputAdornment, List, ListItem, ListItemText, Paper, Stack, TextField, Tooltip } from "@mui/material"
import useBaseApi from "hooks/useBaseApi"
import { Fragment, useMemo, useState } from "react"
import { getPlanSeats } from "utilities/getPlan"

const contactFilter = text => contact => contact.name.includes(text) || contact.organization.includes(text) || contact.email.includes(text)

const paperStyle = {
  padding: theme => theme.spacing(2),
  overflowY: 'scroll',
}

const contactIconStyle = {
  color: theme => theme.palette.secondary.main,
}

const ContactsInternal = ({
  seats,
  members,
  planId,
  organizationId,
  onSeatGranted,
}) => {
  console.log({
    seats,
    members,
    planId,
    organizationId,
  })
  const [ loading, setLoading ] = useState(false)
  const [text, setText] = useState('')
  const maxSeats = getPlanSeats(planId)
  const api = useBaseApi()

  const handleSeat = (contact, shouldHaveSeat) => async () => {
    setLoading(true)
    const {message} = await api.organizations(organizationId).users(contact).update({
      shouldHaveSeat,
    })
    if (message)
      alert(message)
    else if (shouldHaveSeat)
      alert(`${contact} has been granted a seat. They will need to log out and back in for the changes to take effect.`)
    else
      alert(`${contact}'s seat has been removed and can now be granted to another member.`)
    onSeatGranted()
    setLoading(false)
  }


  const orgUsers = useMemo(() => {
    const seatSet = new Set(seats.slice(0, maxSeats).map(a => a.email))
    return {
      seats: seats,
      noSeats: members.filter(a => !seatSet.has(a.email)),
    }
  }, [ seats, members, maxSeats ])
  const filter = contactFilter(text)
  const filteredUsers = {
    seats: orgUsers.seats.filter(filter),
    noSeats: orgUsers.noSeats.filter(filter),
  }

  return (
    <Stack spacing={2}>
      <TextField
        placeholder='Search'
        value={text}
        onChange={e => setText(e.target.value)}
        fullWidth
        InputProps={{
          sx: { background: 'white' },
          startAdornment: <InputAdornment position='start'><PermContactCalendar sx={contactIconStyle} /></InputAdornment>,
        }}
      />
      <Paper sx={paperStyle}>
        {loading && <CircularProgress />}
        <List>
          {filteredUsers.seats.map(contact => (
            <Fragment key={contact.email}>
              <ListItem
                secondaryAction={(
                  <Stack flexWrap='nowrap' spacing={1} direction='row'>
                    <Tooltip title='Remove From Seat'>
                      <IconButton onClick={handleSeat(contact.email, false)}>
                        <PersonRemove />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              >
                <ListItemText
                  primary={contact.name}
                  secondary={contact.email}
                />
              </ListItem>
              <Divider component='li' />
            </Fragment>
          ))}
          <Divider sx={{ backgroundColor: 'black' }} />
          {filteredUsers.noSeats.map(contact => (
            <Fragment key={contact.email}>
              <ListItem
                secondaryAction={(
                  <Stack flexWrap='nowrap' spacing={1} direction='row'>
                    <Tooltip title='Grant a Seat'>
                      <IconButton color='primary' disabled={seats.length >= maxSeats} onClick={handleSeat(contact.email, true)}>
                        <PersonAdd />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              >
                <ListItemText
                  primary={contact.name}
                  secondary={contact.email}
                />
              </ListItem>
              <Divider component='li' />
            </Fragment>
          ))}
        </List>
      </Paper>
    </Stack>
  )
}

export default ContactsInternal
