import { Divider, Grid } from "@mui/material"
import BaseCard from "components/BaseCard"
import FileRequestLineItem from "components/FileRequestLineItem"
import useBaseApi from "hooks/useBaseApi"
import { useEffect, useState } from "react"

const Log = () => {
  const [ sent, setSent ] = useState({log: [], cursor: undefined })
  const [ received, setReceived ] = useState({log: [], cursor: undefined })
  const api = useBaseApi()

  useEffect(() => {
    api.log().sent()
      .then(setSent)
  }, [api])

  useEffect(() => {
    api.log().received()
      .then(setReceived)
  }, [api])

  const [ inbox, setInbox ] = useState({
    received: [],
    sent: [],
  })
  useEffect(() => {
    api.files().getAll()
      .then(files => {
        setInbox(files)
      })
  }, [api])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={6}>
        <BaseCard title='Sent'>
          {inbox.sent.concat(sent.log).map(file => (
            <FileRequestLineItem
              {...file}
              isSender
              disabled
            />
          ))}
        </BaseCard>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <BaseCard title='Received'>
          {inbox.received.concat(received.log).map(file => (
            <FileRequestLineItem
              {...file}
              disabled
            />
          ))}
        </BaseCard>
      </Grid>
    </Grid>
  )
}

export default Log
