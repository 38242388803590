import { Check, Delete, HourglassEmpty, PermContactCalendar } from "@mui/icons-material"
import { TextField, Paper, List, InputAdornment, ListItem, ListItemButton, IconButton, ListItemText, Divider, Stack, CircularProgress, Tooltip } from "@mui/material"
import { useState, Fragment, useEffect } from "react"

const paperStyle = {
  padding: theme => theme.spacing(2),
  overflowY: 'scroll',
}

const contactIconStyle = {
  color: theme => theme.palette.secondary.main,
}

const contactFilter = text => contact => contact.name.includes(text) || contact.organization.includes(text) || contact.email.includes(text)

const ContactsView = ({
  onContactSelected,
  currentSelectedContact,
  initialContactEmail,
  loading,
  onApprove = () => {},
  contacts,
}) => {
  const [text, setText] = useState('')

  useEffect(() => {
    if (initialContactEmail) {
      const initial = contacts.approved.find(a => a.email === initialContactEmail)
      if (initial)
        onContactSelected(initial)
    }
  }, [ initialContactEmail, contacts, onContactSelected ])

  const filter = contactFilter(text)
  const filteredContacts = text ? {
    pending: contacts.pending.filter(filter),
    approved: contacts.approved.filter(filter),
  } : contacts

  return (
    <Stack spacing={2}>
      <TextField
        placeholder='Search'
        value={text}
        onChange={e => setText(e.target.value)}
        fullWidth
        InputProps={{
          sx: {background: 'white'},
          startAdornment: <InputAdornment position='start'><PermContactCalendar sx={contactIconStyle} /></InputAdornment>,
        }}
      />
      <Paper sx={paperStyle}>
        {loading && <CircularProgress />}
        <List>
          {filteredContacts.pending.map(contact => (
            <Fragment key={contact.email}>
              <ListItem
                secondaryAction={
                  contact.canAccept ? (
                    <Stack flexWrap='nowrap' spacing={1} direction='row'>
                      <Tooltip title='Confirm Contact'>
                        <IconButton color='primary' onClick={onApprove(contact, true)}>
                          <Check />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Reject Contact'>
                        <IconButton edge='end' onClick={onApprove(contact, false)}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  ) : (
                    <Tooltip title='Pending Contact Confirmation'>
                      <div>
                          <IconButton disabled edge='end'>
                            <HourglassEmpty />
                          </IconButton>
                      </div>
                    </Tooltip>
                  )
                }
              >
                <ListItemText
                  primary={contact.name}
                  secondary={contact.organization}
                />
              </ListItem>
              <Divider component='li' />
            </Fragment>
          ))}
          <Divider sx={{backgroundColor: 'black'}} />
          {filteredContacts.approved.map(contact => (
            <Fragment key={contact.email}>
              <ListItemButton onClick={() => onContactSelected(contact)} selected={currentSelectedContact?.email === contact.email}>
                <ListItemText
                  primary={contact.name}
                  secondary={contact.organization ?? contact.email}
                />
              </ListItemButton>
              <Divider component='li' />
            </Fragment>
          ))}
        </List>
      </Paper>
    </Stack>
  )
}

export default ContactsView
