import { Edit } from "@mui/icons-material"
import { Grid, IconButton, Paper, Typography } from "@mui/material"

const marginBottomStyle = {
  marginBottom: theme => theme.spacing(2),
}

const basePaperStyle = {
  padding: theme => theme.spacing(2),
}

const innerPaperStyle = {
  ...basePaperStyle,
  overflowY: 'scroll',
}

const outerPaperStyle = {
  ...basePaperStyle,
  background: theme => theme.palette.grey[200],
}

const ActionCard = ({ title, children, actions }) => {
  return (
    <Paper sx={outerPaperStyle}>
      <Grid sx={marginBottomStyle} container justifyContent='space-between'>
        <Grid item>
          <Typography variant='h5'>{title}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            {actions.map(({ Icon, action, disabled }) => (
              <Grid item key={action}>
                <IconButton onClick={action} disabled={disabled}>
                  <Icon />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Paper elevation={0} sx={innerPaperStyle}>
        {children}
      </Paper>
    </Paper>
  )
}

export default ActionCard
