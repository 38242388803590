import { Add, Edit } from "@mui/icons-material"
import { Button, Grid, Stack, TextField } from "@mui/material"
import useBaseApi from "hooks/useBaseApi"
import { useState } from "react"
import { Link as RouterLink } from 'react-router-dom'
import { cleanEmail, validEmail } from "utilities/email"
import BaseCard from '../components/BaseCard'
import ContactDetail from "./ContactDetail"
import ContactsExternal from "./ContactsExternal"
import { marginBottomStyle } from "./dashboardStyles"


const NewContact = () => {
  const [ email, setEmail ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const api = useBaseApi()
  const cleanedEmail = cleanEmail(email)
  const emailValid = validEmail(cleanedEmail)
  const onClick = async () => {
    setLoading(true)
    const {newInvite} = await api.users().connections(cleanedEmail).create()
    const msg = newInvite ? `An invitation to join relay has been extended to ${cleanedEmail}. Be sure to send in a good word for us too!` : `A connection request has been sent successfully to ${cleanedEmail}.`
    alert(msg)
    setEmail('')
    setLoading(false)
  }
  return (
    <>
      <BaseCard title='New Connection' removeInnerPaper>
        <TextField value={email} onChange={e => setEmail(e.target.value)} fullWidth label='Email' sx={{ ...marginBottomStyle, background: theme => theme.palette.background.paper }} />
        <Button onClick={onClick} disabled={loading || !emailValid} fullWidth color='primary' variant='contained'>Send Invitation</Button>
      </BaseCard>
    </>
  )
}

const ContactsPage = () => {
  const [ currentContact, setCurrentContact ] = useState({})
  const contactSelected = !!currentContact.email
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} sx={{ paddingBottom: theme => theme.spacing(2) }}>
          <BaseCard title='Contacts' removeInnerPaper>
            <ContactsExternal
              onContactSelected={setCurrentContact}
              currentSelectedContact={currentContact}
            />
          </BaseCard>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container direction='column' spacing={2}>
            <Grid item sx={{flexGrow: 1}}>
              <BaseCard title='Contact Details'>
                <Stack justifyContent='space-between' spacing={2}>
                  <ContactDetail
                    contact={currentContact}
                  />
                  <Stack spacing={2} direction='row'>
                    <Button disabled={!contactSelected} variant='contained' fullWidth startIcon={<Add />} component={RouterLink} to={`/dashboard/new-document?email=${currentContact.email}`}>New Document</Button>
                    <Button disabled={!contactSelected} variant='contained' fullWidth startIcon={<Edit />} sx={{visibility: 'hidden'}} color='inherit'>Edit</Button>
                  </Stack>
                </Stack>
              </BaseCard>
            </Grid>
            <Grid item>
              <NewContact />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ContactsPage
