import { Button, TextField } from "@mui/material"
import useBaseApi from 'hooks/useBaseApi'
import useTextFieldHandler from 'hooks/useTextFieldHandler'
import { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { browserCompatibleForEncryption, createKeys, hashPassword } from 'utilities/encrypter'
import { bottomMargin, button, doubleBottomMargin } from './styles'

const VerifyAccount = () => {
  const [ accountData, setAccountData ] = useState({
    name: '',
    password: '',
    confirmPassword: '',
  })
  const [accountCreated, setAccountCreated] = useState({ submitted: false, status: '' })
  const accountHandler = useTextFieldHandler(setAccountData)
  const { token } = useParams()
  const api = useBaseApi()

  const browserCompatible = browserCompatibleForEncryption()
  useEffect(() => {
    if (!browserCompatible)
      return alert('Your browser does not have the necessary utilities to securely transfer documents. Please use a modern browser, updated to the latest version.')
  }, [browserCompatible])

  const onVerifyAccount = async () => {
    const {password, confirmPassword, ...d} = accountData
    if (password !== confirmPassword) {
      return alert('Passwords do not match!')
    }
    try {
      const passwordHash = hashPassword(password)
      setAccountCreated(d => ({ ...d, status: 'Validating Data' }))
      const r = await api.users().verify({
        ...d,
        passwordHash,
        token,
      })
      const { user: { salt }, token: fullAccessToken } = r
      setAccountCreated(d => ({ ...d, status: 'Creating Secrets' }))
      const keys = await createKeys({ password, salt })
      await api.users().keys().create(keys, `Bearer ${fullAccessToken.token}`)
      setAccountCreated(d => ({ ...d, status: 'Logging In' }))
      await api.token.login({ token: fullAccessToken, password, salt, encryptedPrivateJwk: keys.encryptedPrivateJwk })
    } catch(e) {
      console.error(e)
      alert('Something went wrong. Please try again.')
      setAccountCreated(d => ({ ...d, status: '' }))
    }
  }

  const errors = {
    invalidPassword: accountData.password.length < 6,
    invalidPasswordConfirm: accountData.password !== accountData.confirmPassword,
  }
  const disabledSubmit = [errors.invalidPassword, errors.invalidPasswordConfirm].some(a => a)

  if (api.loggedIn())
    return <Redirect to='/dashboard' />

  return (
    <>
      <TextField sx={bottomMargin} label='Name' fullWidth name='name' value={accountData.name} onChange={accountHandler} />
      <TextField sx={bottomMargin} label='Password' type='password' fullWidth name='password' value={accountData.password} onChange={accountHandler} error={errors.invalidPassword} helperText='Password must contain 6 characters' />
      <TextField sx={doubleBottomMargin} label='Confirm Password' type='password' fullWidth name='confirmPassword' value={accountData.confirmPassword} onChange={accountHandler} error={errors.invalidPasswordConfirm} helperText='Passwords must match' />
      <Button sx={button} disabled={disabledSubmit || !!accountCreated.status} onClick={onVerifyAccount}>
        {accountCreated.status || 'Create Account'}
      </Button>
    </>
  )
}

export default VerifyAccount
