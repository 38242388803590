import useBaseApi from "hooks/useBaseApi"
import { encryptDocumentWithSecret } from "utilities/encrypter"
import { PermContactCalendar, CheckCircle, HourglassEmpty } from "@mui/icons-material"
import { Button, Link, Grid, Paper, Typography, CircularProgress, Stack, LinearProgress } from "@mui/material"
import { marginBottomStyle, paddingStyle } from '../dashboardStyles'

import FileOverview from "./FileOverview"
import axios from "axios"

const paperStyle = paddingStyle


const Confirmation = ({ model, dispatch }) => {
  const api = useBaseApi()
  const sendDocuments = async () => {
    const getFileContent = async file => new Promise((res, rej) => {
      const fileReader = new FileReader()
      fileReader.onload = e => res(e.target.result)
      fileReader.onerror = rej
      fileReader.readAsArrayBuffer(file)
    })
    const encryptedSecret = model.recipient.secret
    const uploadFile = async (encryptedFile, doc, docIndex) => {
      // 1. Request new file url
      const {url, fileId} = await api.files().create({
        recieverId: model.recipient.email,
        doc: {
          extension: doc.file.name.split('.').pop(),
          kind: doc.kind,
          recordCount: doc.recordCount,
          priority: doc.priority,
          deadline: doc.deadline,
        },
      })
      // 2. Upload to url
      // await fetch(url, {
      //   method: 'PUT',
      //   body: encryptedFile,
      // })
      await axios.put(url, encryptedFile, {
        onUploadProgress: progressEvent => {
          dispatch({ type: 'uploadingFile', payload: docIndex, progress: progressEvent.loaded * 100 / progressEvent.total })
        },
      })
      // 3. Confirm it was uploaded
      await api.files(fileId).confirmUploaded()
    }
    dispatch({ type: 'uploading' })
    try {
      for (const [docIndex, doc] of model.docs.entries()) {
        dispatch({ type: 'encryptingFile', payload: docIndex })
        const fileContents = await getFileContent(doc.file)
        const encryptedFile = await encryptDocumentWithSecret({ fileContents, key: api.token.secretKey, encryptedSecret })
        dispatch({ type: 'uploadingFile', payload: docIndex, progress: 0 })
        await uploadFile(encryptedFile, doc, docIndex)
        dispatch({ type: 'fileUploaded', payload: docIndex })
      }
      dispatch({ type: 'next' })
    } catch(e) {
      alert(e.response?.data.message ?? 'Something happened on our end. Please try again later.')
    }
  }
  const getFileOverviewChildren = (doc, docIndex) => {
    if (!model.uploading)
      return <Link underline='always' color='secondary' onClick={() => dispatch({ type: 'editFile', payload: docIndex })}>edit details</Link>
    const statusBody = (() => {
      switch(doc.status) {
        case 'Complete':
          return <CheckCircle color='primary' />
        case 'Uploading':
          return <CircularProgress color='primary' variant='determinate' value={doc.progress} />
        case 'Encrypting':
          return <CircularProgress color='primary' />
        default:
          return <HourglassEmpty color='primary' />
      }
    })()
    return (
      <Stack direction='row' spacing={1} alignItems='center'>
        {statusBody}
        <Typography variant='body2' color='primary'>{doc.status ?? 'Pending'}</Typography>
      </Stack>
    )
  }
  return (
    <>
      <Typography color='secondary'>Recipient:</Typography>
      <Paper sx={{ ...marginBottomStyle, ...paperStyle }}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <PermContactCalendar color='secondary' />
          </Grid>
          <Grid item>
            <Typography variant='body1'>{model.recipient.name}</Typography>
            <Typography variant='body2' color='textSecondary'>{model.recipient.organization}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <Typography color='secondary'>Files to be sent: {model.docs.length}</Typography>
      {model.docs.map((file, fileIndex) => (
        <FileOverview
          file={file}
          onRemoveClick={model.uploading ? undefined : () => {
            dispatch({ type: 'removeFile', removeAtIndex: fileIndex })
          }}
        >
          {getFileOverviewChildren(file, fileIndex)}
        </FileOverview>
      ))}
      <Grid container justifyContent='flex-end'>
        <Grid item xs={6}>
          <Button onClick={sendDocuments} disabled={model.uploading || !model.recipient?.email || !model.docs.length} fullWidth size='large' color='primary' variant='contained'>
            Send Documents
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default Confirmation
