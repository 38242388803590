import { PhotoCamera } from "@mui/icons-material"
import { IconButton, Stack } from "@mui/material"
import { useEffect, useRef } from "react"

const PhotoCapture = ({
  onFailToInitialize,
  onImage,
}) => {
  const videoRef = useRef(null)
  const canvasRef = useRef(null)
  useEffect(() => {
    let videoStream
    if (window.navigator?.mediaDevices?.getUserMedia)
      window.navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
        },
      })
        .then(stream => {
          videoStream = stream
          videoRef.current.srcObject = stream
        })
        .catch(onFailToInitialize)
    else
      onFailToInitialize(new Error('This browser does not support video capture'))
    return () => {
      videoStream.getTracks().forEach(track => track.stop())
    }
  }, [onFailToInitialize])

  const onImageCapture = () => {
    const canvas = canvasRef.current
    canvas.width = videoRef.current.videoWidth
    canvas.height = videoRef.current.videoHeight
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0)
    const imageType = 'image/png'
    canvas.toBlob(blob => {
      const file = new File([blob], 'image.png', { type: imageType})
      onImage(file)
    }, imageType)
  }

  return (
    <Stack alignItems='center' justifyContent='center' spacing={4}>
      <video
        autoPlay
        ref={videoRef}
        playsInline
        muted
        style={{width: '80vw'}}
      />
      <IconButton onClick={onImageCapture}>
        <PhotoCamera />
      </IconButton>
      <canvas ref={canvasRef}

      />
    </Stack>
  )
}

export default PhotoCapture
