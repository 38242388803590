import { methods, makeRequester } from './makeRequester'

const baseApi = process.env.REACT_APP_API_BASE

const createBaseApi = token => {
  const r = makeRequester(token.token ? `Bearer ${token.token}` : undefined, baseApi)
  return {
    token,
    loggedIn: () => !!token.token,
    organizations: organizationId => {
      const orgPath = `organizations/${organizationId}`
      return {
        get: r(methods.read, orgPath),
        update: r(methods.update, orgPath),
        delete: r(methods.delete, orgPath),
        subscriptions: () => ({
          change: r(methods.create, `${orgPath}/subscriptions`)
        }),
        users: userId => {
          const orgUserPath = `${orgPath}/users`
          const thisOrgUserPath = `${orgUserPath}/${userId}`
          return {
            update: r(methods.update, thisOrgUserPath),
            invite: r(methods.create, thisOrgUserPath),
          }
        },
      }
    },
    users: (userId = 'current') => {
      const userPath = `users`
      const thisUserPath = `${userPath}/${userId}`
      return {
        logout: token.logout,
        login: r(methods.create, 'login'),
        get: r(methods.read, thisUserPath),
        create: r(methods.create, userPath),
        verify: r(methods.create, `${userPath}/verify`),
        update: r(methods.update, thisUserPath),
        updateUserSubscription: r(methods.update, `${thisUserPath}/subscriptions`),
        connections: connectionId => {
          const connectionPath = `${thisUserPath}/connections`
          const thisConnectionPath = `${connectionPath}/${connectionId}`
          return {
            get: r(methods.read, connectionPath),
            create: r(methods.create, thisConnectionPath), // create invites
            update: r(methods.update, thisConnectionPath), // accept / reject invites
          }
        },
        keys: () => {
          const keyPath = `${thisUserPath}/keys`
          return {
            get: r(methods.read, keyPath),
            create: r(methods.create, keyPath),
          }
        },
        organizationInvites: orgId => {
          const orgUserPath = `${thisUserPath}/organization-invites`
          const thisOrgUserPath = `${orgUserPath}/${orgId}`
          return {
            getAll: r(methods.read, orgUserPath),
            respond: r(methods.create, thisOrgUserPath),
          }
        },
      }
    },
    files: fileId => {
      const filePath = 'files'
      const thisFilePath = `${filePath}/${fileId}`
      return {
        getAll: r(methods.read, filePath),
        get: r(methods.read, `${thisFilePath}`),
        confirmReceived: r(methods.create, `${thisFilePath}/received`),
        create: r(methods.create, filePath),
        confirmUploaded: r(methods.create, `${thisFilePath}/uploaded`)
      }
    },
    log: () => {
      const logPath = 'log'
      return {
        sent: r(methods.read, `${logPath}/Sent`),
        received: r(methods.read, `${logPath}/Received`),
      }
    }
  }
}

export default createBaseApi
