import { Add, Check, Delete } from "@mui/icons-material"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemText, Stack, TextField, Tooltip, Typography } from "@mui/material"
import ActionCard from "components/ActionCard"
import EditCard from "components/EditCard"
import useAuthorization from "hooks/useAuthorization"
import useBaseApi from "hooks/useBaseApi"
import useLoggedInUser from "hooks/useLoggedInUser"
import useOrganization from "hooks/useOrganization"
import { useCallback, useEffect, useState } from "react"
import { cleanEmail, validEmail } from "utilities/email"
import getPlan from "utilities/getPlan"
import ContactDetail from '../ContactDetail'
import ContactsInternal from "../ContactsInternal"
import OrganizationDetail from "../OrganizationDetail"


const useEditSettings = () => {
  const [ edit, setEdit ] = useState(false)
  const triggerEdit = useCallback(() => setEdit(true), [setEdit])
  const endEdit = useCallback(() => setEdit(false), [setEdit])
  return {
    edit,
    triggerEdit,
    endEdit,
  }
}

const InviteMemberDialog = ({
  open,
  onClose,
  organizationId,
}) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const api = useBaseApi()
  const cleanedEmail = cleanEmail(email)
  const emailValid = validEmail(cleanedEmail)
  const onClick = async () => {
    setLoading(true)
    const { invited } = await api.organizations(organizationId).users(cleanedEmail).invite()
    const msg = invited ? `An invitation to join relay has been extended to ${cleanedEmail}. Be sure to send in a good word for us too!` : `Your invite has been sent successfully to ${cleanedEmail}.`
    alert(msg)
    setEmail('')
    setLoading(false)
  }
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm'>
      <DialogTitle>
        Invite to Organization
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1' gutterBottom>
          Once invited, your colleague will still need to login / create an account, and accept the invite in the Account screen.
        </Typography>
        <TextField value={email} onChange={e => setEmail(e.target.value)} disabled={loading} fullWidth label='Email Address' placeholder='email@example.com' />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onClick} disabled={loading || !emailValid} variant='contained'>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

const AccountPage = () => {
  const [ loggedInUserDetails, loggedInUserDispatch] = useLoggedInUser()
  const [ refresh, setRefresh ] = useState(0)
  const [ organizationDetails, organizationDispatch ] = useOrganization(loggedInUserDetails.organization, refresh)

  const [ organizationInvites, setOrganizationInvites ] = useState([])

  const api = useBaseApi()

  const [ showAddMember, setShowAddMember ] = useState(false)
  const onRefresh = useCallback(() => setRefresh(r => r + 1), [])

  useEffect(() => {
    api.users().organizationInvites().getAll()
      .then(invites => {
        if (!invites.error)
          setOrganizationInvites(invites.invites)
      })
  }, [api])

  const editContact = useEditSettings()
  const editOrg = useEditSettings()

  const onUserChange = useCallback(e => {
    const { target: { name, value } } = e
    loggedInUserDispatch({
      type: 'edit',
      field: name,
      value,
    })
  }, [loggedInUserDispatch])

  const onOrgChange = useCallback(e => {
    const {target: {name, value}} = e
    organizationDispatch({
      type: 'edit',
      field: name,
      value,
    })
  }, [organizationDispatch])

  const plan = getPlan(organizationDetails?.subscription?.subscriptionPlanId)
  const paddleId = organizationDetails?.subscription?.subscriptionId
  const {logout} = useAuthorization()
  const onRespondToOrgInvite = (organizationId, accept) => async () => {
    if (!window.confirm(`Are you sure you want to ${accept ? 'accept' : 'reject'} this invitation? ${accept ? 'You will be logged out for these changes to take effect.' : ''}`))
      return
    await api.users().organizationInvites(organizationId).respond({accept})
    if (accept)
      logout()
    else
      setOrganizationInvites(invites => invites.filter(a => a.organizationId !== organizationId))
  }

  const closeInviteDialog = () => setShowAddMember(false)

  return (
    <>
      <InviteMemberDialog
        open={showAddMember}
        onClose={closeInviteDialog}
        organizationId={loggedInUserDetails.organization}
      />
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <Stack spacing={4}>
              <EditCard
                title='Account Details'
                onEdit={editContact.triggerEdit}
                onCancel={() => {
                  editContact.endEdit()
                  loggedInUserDispatch({ type: 'cancel' })
                }}
                onSave={() => {
                  const userDetails = loggedInUserDetails
                  editContact.endEdit()
                  loggedInUserDispatch({ type: 'save' })
                  api.users().update(userDetails)
                }}
                editing={editContact.edit}
              >
                <ContactDetail
                  contact={loggedInUserDetails}
                  editing={editContact.edit}
                  onChange={onUserChange}
                />
              </EditCard>
              <EditCard
                title='Organization Details'
                onEdit={editOrg.triggerEdit}
                onCancel={() => {
                  editOrg.endEdit()
                  organizationDispatch({ type: 'cancel' })
                }}
                onSave={() => {
                  const orgDetails = organizationDetails
                  editOrg.endEdit()
                  organizationDispatch({ type: 'save' })
                  api.organizations(loggedInUserDetails.organization).update(orgDetails)
                }}
                editing={editOrg.edit}
                disabled={organizationDetails.admin !== loggedInUserDetails.email}
              >
                {organizationInvites.length < 1 ? null : (
                  <List>
                    {organizationInvites.map(invite => (
                      <ListItem
                        secondaryAction={(
                          <Stack flexWrap='nowrap' spacing={1} direction='row'>
                            <Tooltip title='Join'>
                              <IconButton color='primary' onClick={onRespondToOrgInvite(invite.organizationId, true)}>
                                <Check />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Reject Invitation'>
                              <IconButton edge='end' onClick={onRespondToOrgInvite(invite.organizationId, false)}>
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        )}
                      >
                        <ListItemText primary={`${invite.organization} wants you to join their organization.`} />
                      </ListItem>
                    ))}
                  </List>
                )}
                <Divider />
                <OrganizationDetail
                  organization={{
                    ...organizationDetails,
                    organization: organizationDetails?.pk?.replace('Organization#', ''),
                    plan: plan.name,
                    planId: plan.planId,
                    paddleId,
                  }}
                  editing={editOrg.edit}
                  onChange={onOrgChange}
                />
              </EditCard>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ActionCard title='Organization Members' actions={[{
              Icon: Add,
              action: () => setShowAddMember(true),
            }]}>
              <ContactsInternal
                planId={plan.planId ?? ''}
                organizationId={organizationDetails?.pk?.replace('Organization#', '') ?? ''}
                onSeatGranted={onRefresh}
                {...organizationDetails}
              />
            </ActionCard>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AccountPage
