import { Close, PhotoCamera, UploadFile } from "@mui/icons-material"
import { AppBar, Box, Container, Dialog, IconButton, Paper, Stack, Toolbar, Typography } from "@mui/material"
import { useState, useRef, useCallback, useMemo } from "react"
import NextButton from './NextButton'
import fileComplete from "./fileComplete"
import { paddingStyle } from '../dashboardStyles'
import useIsLargeScreen from "hooks/useIsLargeScreen"
import PhotoCapture from "components/PhotoCapture"
const paperStyle = paddingStyle


const UploadDocument = ({ model, dispatch }) => {
  const inputRef = useRef()
  const addFile = file => dispatch({ type: 'appendFile', payload: file })
  const [isDragging, setIsDragging] = useState(false)
  const file = (() => {
    if (model.docs.length === 0) return null
    const file = model.docs[model.docs.length - 1]
    if (fileComplete(file) && !file.copied) return null
    return file
  })()
  const isLargeScreen = useIsLargeScreen()
  const triggerInput = () => inputRef.current.click()
  const [ showPhotoPicker, setShowPhotoPicker ] = useState(false)
  const [ openPhotoPicker, closePhotoPicker ] = useMemo(() => [ true, false ].map(open => () => setShowPhotoPicker(open)), [])
  const onPhotoPickerFail = useCallback(e => {
    console.error(e)
    alert('Your browser does not appear to support this feature')
    closePhotoPicker()
  }, [closePhotoPicker])
  return (
    <>
      {isLargeScreen ? (
        <>
          <Paper
            sx={{ ...paperStyle, cursor: 'pointer' }}
            onClick={triggerInput}
            onDrop={e => {
              e.preventDefault()
              setIsDragging(false)
              const files = e.dataTransfer.files
              if (files)
                addFile(files[0])
            }}
            onDragOver={e => {
              e.preventDefault()
              setIsDragging(true)
            }}
            onDragLeave={e => {
              e.preventDefault()
              setIsDragging(false)
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 200, justifyContent: 'center', alignItems: 'center', color: theme => theme.palette.primary.main }}>
              <UploadFile color='inherit' />
              <Typography variant='body1' color='inherit'>{isDragging ? 'Drop the Document Here' : file?.file?.name ?? 'Upload Document'}</Typography>
              <input ref={inputRef} type='file' style={{ display: 'none' }} onChange={e => addFile(e.target.files[0])} />
            </Box>
          </Paper>
        </>
      ) : (
          <>
            <Dialog
              fullScreen
              open={showPhotoPicker}
              onClose={closePhotoPicker}
            >
              <AppBar position="relative">
                <Toolbar>
                  <IconButton
                    edge='start'
                    color='inherit'
                    onClick={closePhotoPicker}
                  >
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Container>
                <PhotoCapture
                  onFailToInitialize={onPhotoPickerFail}
                  onImage={image => {
                    addFile(image)
                    closePhotoPicker()
                  }}
                />
              </Container>
            </Dialog>
            <Paper>
              <Stack sx={{ height: 200 }} justifyContent='center' alignItems='center'>
                <Typography variant='body1'>{file?.file?.name ?? 'Upload File'}</Typography>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: theme => theme.palette.primary.main }}>
                    <IconButton color='inherit' onClick={triggerInput}>
                      <UploadFile color='inherit' />
                    </IconButton>
                    <input ref={inputRef} type='file' style={{ display: 'none' }} onChange={e => addFile(e.target.files[0])} />
                  </Box>
                  <Typography variant='h6' color='textSecondary'>or</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: theme => theme.palette.primary.main }}>
                    <IconButton color='inherit' onClick={openPhotoPicker}>
                      <PhotoCamera color='inherit' />
                    </IconButton>
                  </Box>
                </Stack>
              </Stack>
            </Paper>
          </>
      )}
      <Typography variant='body1' color='secondary'>Accepted file types: .jpg, .png, .pdf, .csv</Typography>
      <NextButton
        dispatch={dispatch}
        disabled={!file}
      >
        Continue to Details
      </NextButton>
    </>
  )
}
export default UploadDocument
