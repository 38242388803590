import { useMemo } from 'react'
import createBaseApi from '../utilities/baseApi'
import useAuthorization from './useAuthorization'

const useBaseApi = () => {
  const token = useAuthorization()
  return useMemo(() => createBaseApi(token), [token])
}

export default useBaseApi
