import {ThemeProvider, createTheme, CssBaseline} from '@mui/material'
import { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Dashboard from './Dashboard'
import Home from './Home'
import { AuthorizationProvider } from './hooks/useAuthorization'

const theme = createTheme({
  palette: {
    primary: {
      main: '#129D99',
    },
    secondary: {
      main: '#3A6B72',
    },
    background: {
      default: '#1a464d',
      appBar: '#c9f3f0',
      priority: 'rgba(231, 110, 102, 0.6)',
    },
  }
})

const App = () => {
  useEffect(() => {
    console.log('USING VERSION 0.6')
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthorizationProvider>
        <BrowserRouter>
          <Switch>
            <Route path='/dashboard'>
              <Dashboard />
            </Route>
            <Route path='/'>
              <Home />
            </Route>
          </Switch>
        </BrowserRouter>
      </AuthorizationProvider>
    </ThemeProvider>
  )
}

export default App
