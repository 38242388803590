import { MenuItem, Grid, TextField, Typography, InputAdornment, Tooltip } from "@mui/material"
import YesNoSelection from "components/YesNoSelection"
import { marginBottomStyle } from '../dashboardStyles'
import NextButton from './NextButton'
import fileComplete from "./fileComplete"
import FileOverview from "./FileOverview"

import {Help} from '@mui/icons-material'

const DocumentDetails = ({ model, dispatch }) => {
  const file = model.docs[model.currentEditing]
  const handler = e => dispatch({
    type: 'updateFileDetails',
    field: e.target.name,
    value: e.target.value,
  })
  const buttonClickHandler = field => value => dispatch({
    type: 'updateFileDetails',
    field,
    value,
  })
  return (
    <>
      <FileOverview
        file={file}
        onRemoveClick={() => {
          dispatch({ type: 'removeFile', navigateNext: fileComplete(file) })
        }}
      />
      <Grid container spacing={2} alignItems='center' sx={marginBottomStyle}>
        <Grid item xs={6}><Typography color='secondary' align='right' variant='body1'>What kind of record? *</Typography></Grid>
        <Grid item xs={6}>
          <TextField value={file.kind} name='kind' onChange={handler} select fullWidth>
            {[
              'PHI',
              'Contract / Agreement',
              'Orders',
              'Miscellaneous',
            ].map(v => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}><Typography color='secondary' align='right' variant='body1'>How many records are in this document? *</Typography></Grid>
        <Grid item xs={6}><TextField name='recordCount' value={file.recordCount} onChange={handler} type='number' fullWidth placeholder='input number' /></Grid>

        <Grid item xs={6}><Typography color='secondary' align='right' variant='body1'>Is this record high priority? *</Typography></Grid>
        <Grid item xs={6}>
          <YesNoSelection
            value={file.priority}
            onChange={buttonClickHandler('priority')}
          />
        </Grid>

        <Grid item xs={6}><Typography color='secondary' align='right' variant='body1'>Is there a deadline?</Typography></Grid>
        <Grid item xs={6}>
          <TextField fullWidth placeholder='dd/mm/yyyy' InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip title='Deadline is when action is required on this document, if any.'>
                  <Help />
                </Tooltip>
              </InputAdornment>
            ),
          }} />
        </Grid>
      </Grid>
      <NextButton
        dispatch={dispatch}
        disabled={!fileComplete(file)}
      >
        Confirm Details
      </NextButton>
    </>
  )
}

export default DocumentDetails
