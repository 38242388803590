import { AppBar, Toolbar } from "@mui/material"

const toolbarStyle = {
  backgroundColor: theme => theme.palette.background.appBar
}

const appBarStyle = {
  zIndex: (theme) => theme.zIndex.drawer + 1,
}

const TopBar = ({children}) => {
  return (
    <AppBar sx={appBarStyle}>
      <Toolbar position='fixed' sx={toolbarStyle}>
        <img
          style={{height: 48}}
          src='logo.png'
          alt='Relay Logo'
        />
        {children}
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
