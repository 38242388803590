import useBaseApi from "hooks/useBaseApi"
import { useState, useEffect } from "react"
import { createEncryptedSecret } from 'utilities/encrypter'
import ContactsView from "./ContactsView"


const ContactsExternal = ({ onContactSelected, currentSelectedContact, initialContactEmail }) => {
  const [contacts, setContacts] = useState({
    pending: [],
    approved: [],
  })
  const [ loading, setLoading ] = useState(false)
  const api = useBaseApi()

  useEffect(() => {
    setLoading(true)
    api.users().connections().get()
      .then(d => {
        setLoading(false)
        setContacts(d.reduce((acc, contact) => {
          if (contact.pending)
            acc.pending.push(contact)
          else
            acc.approved.push(contact)
          return acc
        }, { pending: [], approved: [] }))
      })
  }, [api])

  const onApprove = (contact, approve) => async () => {
    setLoading(true)

    const { key: recipientPublicJwk } = await api.users(contact.email).keys().get()
    const { key: senderPublicJwk } = await api.users().keys().get()
    if (!(recipientPublicJwk && senderPublicJwk)) {
      setLoading(false)
      return alert(`Please invite ${contact.email} to the platform to securely share documents.`)
    }
    const { sender, recipient } = await createEncryptedSecret({
      senderPublicJwk,
      recipientPublicJwk,
    })

    const { error, message } = await api.users().connections(contact.email).update({
      approve,
      currentUserSecret: sender,
      connectionSecret: recipient,
    })
    setLoading(false)
    if (message)
      return alert(error)
    delete contact.canAccept
    delete contact.pending
    setContacts({
      approved: approve ? [contact, ...contacts.approved] : contacts.approved,
      pending: contacts.pending.filter(a => a.email !== contact.email),
    })
  }

  return (
    <ContactsView
      onContactSelected={onContactSelected}
      currentSelectedContact={currentSelectedContact}
      initialContactEmail={initialContactEmail}
      loading={loading}
      onApprove={onApprove}
      contacts={contacts}
    />
  )
}

export default ContactsExternal
