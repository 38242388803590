import { Paper, Typography } from "@mui/material"

const marginBottomStyle = {
  marginBottom: theme => theme.spacing(2),
}

const basePaperStyle = {
  padding: theme => theme.spacing(2),
}

const innerPaperStyle = {
  ...basePaperStyle,
  overflowY: 'scroll',
}

const outerPaperStyle = {
  ...basePaperStyle,
  background: theme => theme.palette.grey[200],
}

const BaseCard = ({title, children, removeInnerPaper}) => {
  const innards = removeInnerPaper ? children : (
    <Paper elevation={0} sx={innerPaperStyle}>
      {children}
    </Paper>
  )
  return (
    <Paper sx={outerPaperStyle}>
      <Typography sx={marginBottomStyle} variant='h5'>{title}</Typography>
      {innards}
    </Paper>
  )
}

export default BaseCard
