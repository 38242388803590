import { Button, Grid, TextField, Typography } from "@mui/material"
import useBaseApi from 'hooks/useBaseApi'
import useTextFieldHandler from 'hooks/useTextFieldHandler'
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { cleanEmail, commonDomain, invalidEmail as isInvalidEmail } from 'utilities/email'
import { browserCompatibleForEncryption } from 'utilities/encrypter'
import { bottomMargin, button, doubleBottomMargin } from './styles'

const EmailSent = ({
  email,
}) => {
  return (
    <>
      <Typography
        sx={doubleBottomMargin}
        variant='body1'>
          We sent a link to {email}. Please follow the link to confirm your email address and finalize setting up your account.
        </Typography>
    </>
  )
}

const AccountDetails = ({
  email,
  onChange,
  onSubmit,
  status,
}) => {
  const errors = {
    invalidEmail: isInvalidEmail(cleanEmail(email)),
  }
  const disabledSubmit = [errors.invalidEmail].some(a => a)
  const organization = (m => {
    if (commonDomain(m))
      return m
    const splitEmail = m.split('@')
    return splitEmail.length > 1 ? splitEmail[splitEmail.length - 1] : ''
  })(email)
  return (
    <>
      <TextField sx={bottomMargin} label='Email' fullWidth name='email' value={email} onChange={onChange} error={errors.invalidEmail} helperText={errors.invalidEmail} />
      <TextField sx={bottomMargin} label='Organization' fullWidth name='organization' value={organization} disabled />
      <CreateAccountFooter disabled={disabledSubmit || !!status} onSubmit={onSubmit}>
        {status || 'Next'}
      </CreateAccountFooter>
    </>
  )
}

const CreateAccountFooter = ({onSubmit, disabled, children}) => (
  <Grid container justifyContent='flex-end'>
    <Grid item xs={12}>
      <Button onClick={onSubmit} disabled={disabled} size='large' fullWidth sx={button} color='primary' variant='contained'>
        {children}
      </Button>
    </Grid>
    <Grid item>
      <Button sx={button} component={RouterLink} to='/'>
        Login with Existing Account
      </Button>
    </Grid>
  </Grid>
)

const CreateAccount = () => {
  const [ accountData, setAccountData ] = useState({
    email: '',
    organization: '',
  })
  const [ accountCreated, setAccountCreated ] = useState({ submitted: false, status: '' })
  const accountHandler = useTextFieldHandler(setAccountData)

  const api = useBaseApi()

  const browserCompatible = browserCompatibleForEncryption()
  useEffect(() => {
    if (!browserCompatible)
      return alert('Your browser does not have the necessary utilities to securely transfer documents. Please use a modern browser, updated to the latest version.')
  }, [browserCompatible])

  const onCreateAccount = async () => {
    setAccountCreated({ submitted: false, status: 'Sending Email' })
    const { email } = accountData

    const {message} = await api.users().create({
      email: cleanEmail(email),
    })
    if (message) {
      alert(message)
    }
    setAccountCreated(({
      submitted: !message,
      status: 'Email Sent',
    }))
  }

  if (!accountCreated.submitted)
    return (
      <AccountDetails
        {...accountData}
        status={accountCreated.status}
        onSubmit={onCreateAccount}
        onChange={accountHandler}
      />
    )
  return (
    <EmailSent
      {...accountData}
    />
  )
}

export default CreateAccount
