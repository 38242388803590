import { Check, CheckCircle, Close, Error as ErrorIcon, Warning } from "@mui/icons-material"
import { Box, ButtonBase, Dialog, DialogContent, DialogTitle, Divider, Grid, TextField, Typography, useTheme, Stack, Button } from "@mui/material"
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import { useState } from "react"
import useBaseApi from 'hooks/useBaseApi'
import { fileRequester } from "utilities/makeRequester"
import { decryptDocumentWithSecret } from "utilities/encrypter"

dayjs.extend(customParseFormat)
dayjs.extend(utc)


const RequestStatus = ({status}) => {
  let Icon
  let color
  let message
  let messageColor
  const theme = useTheme()
  switch(status) {
    case 'priority':
      message =  'High Priority'
      Icon = ErrorIcon
      color = theme.palette.background.priority
      break
    case 'sent':
      message = 'Sent'
      Icon = ErrorIcon
      color = theme.palette.grey[300]
      messageColor = color
      break
    case 'received':
      message = 'Received'
      Icon = CheckCircle
      color = theme.palette.primary.main
      break
    case 'rejected':
      message = 'Rejected'
      Icon = Warning
      color = theme.palette.background.priority
      messageColor = color
      break
    case 'not-received':
      message = 'Not Received'
      Icon = Warning
      color = theme.palette.background.priority
      messageColor = color
      break
    default:
      return null
  }
  console.log({
    message,
    Icon,
    color,
    messageColor,
    status
  })
  return (
    <Grid container alignItems='center' direction='column'>
      <Grid item>
        <Icon style={{color}} />
      </Grid>
      <Grid item>
        <Typography style={{color: messageColor}} variant='body1'><strong>{message}</strong></Typography>
      </Grid>
    </Grid>
  )
}

const flexGrow = {
  flexGrow: 1,
}

const flexBasis = {
  flexBasis: 120,
  flexShrink: 0,
  flexGrow: 0,
}

const unselectedBox = {
  textAlign: 'left',
  cursor: 'pointer',
  width: '100%',
  padding: theme => theme.spacing(2),
}

const selectedBox = {
  ...unselectedBox,
  background: theme => theme.palette.action.hover,
}

const maxWidth = {
  width: '100%',
}

const FileItemUser = ({user, isSender}) => (
  <>
    {isSender? 'From' : 'To'}: {user.name}, {user.organization}
  </>
)

const FileDetailDialog = ({doc, pk, open, onClose, onFileAction}) => {
  const [ loading, setLoading ] = useState(false)
  const api = useBaseApi()
  const theme = useTheme()
  const [ btnState, setBtnState ] = useState(null)

  const btnData = (() => {
    switch (btnState) {
      case 'accept':
        return {
          primary: {
            text: 'Confirm',
            action: async () => {
              setLoading(true)
              const fileId = pk.replace('File#', '')
              const { url, encryptedSecret, extension } = await api.files(fileId).get()
              await fileRequester({
                url,
                transformer: async response => {
                  const decoder = new TextDecoder()
                  const fileContents = decoder.decode(response.buffer)
                  return decryptDocumentWithSecret({ fileContents, encryptedSecret, key: api.token.secretKey })
                },
                fileName: `${fileId}.${extension}`,
              })
              await api.files(fileId).confirmReceived({
                downloaded: true,
              })
              setLoading(false)
              onFileAction(pk)
              onClose()
            },
          },
          secondary: {
            text: 'Cancel',
            action: () => setBtnState(null),
          },
          confirm: 'Download Record(s)?',
        }
      case 'reject':
        return {
          primary: {
            text: 'Confirm',
            action: async () => {
              setLoading(true)
              const fileId = pk.replace('File#', '')
              await api.files(fileId).confirmReceived({
                downloaded: false,
              })
              setLoading(false)
              onFileAction(pk)
              onClose()
            },
          },
          secondary: {
            text: 'Cancel',
            action: () => setBtnState(null),
          },
          confirm: 'Reject Record(s)?',
        }
      default:
        return {
          primary: {
            text: 'Accept & Download',
            action: () => setBtnState('accept'),
          },
          secondary: {
            text: 'Reject Document',
            action: () => setBtnState('reject'),
          },
          confirm: '',
        }
    }
  })()

  return (
    <Dialog open={open} onClose={loading ? undefined : onClose}>
      <DialogTitle>
        New Message
      </DialogTitle>
      <DialogContent>
        {[
          {label: 'Record Type(s)', multiple: true, name: 'kind'},
          {label: 'Number of Records', name: 'recordCount'},
          {label: 'Priority', value: doc.priority ? 'High' : 'Low', makeRed: doc.priority},
          {label: 'Deadline', name: 'deadline' },
        ].map(({label, name, multiple, value, makeRed}) => (
          <TextField
            disabled
            variant='standard'
            margin='dense'
            fullWidth
            label={label}
            color={makeRed ? 'error' : 'primary'}
            InputProps={{
              inputProps: {
                style: {
                  WebkitTextFillColor: makeRed ? theme.palette.error.main : theme.palette.text.primary,
                  color: makeRed ? theme.palette.error.main : theme.palette.text.primary,
                },
              },
            }}
            value={value ? value : (Array.isArray(doc[name]) ? doc[name].join(',') : doc[name])}
          />
        ))}
        <Typography sx={{marginTop: theme => theme.spacing(2), marginBottom: theme => theme.spacing(2)}} color='primary' variant='h6'>{btnData.confirm}</Typography>
        <Stack flexWrap='nowrap' direction='row' spacing={4}>
          <Button disabled={loading} startIcon={<Check />} size='large' variant='contained' color='primary' onClick={btnData.primary.action}>
            {btnData.primary.text}
          </Button>
          <Button disabled={loading} startIcon={<Close />} size='large' variant='contained' color='inherit' onClick={btnData.secondary.action}>
            {btnData.secondary.text}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

const FileRequestLineItem = ({
  doc,
  expires,
  pk,
  reciever,
  sender,
  isSender,
  onFileAction,
  disabled: disabledProp,
  ...rest
}) => {
  const [ showDetails, setShowDetails ] = useState(false)
  const [ hover, setHover ] = useState(false)
  const formattedDate = dayjs.utc(expires, 'YYYY#MM#DD#HH').local().format('D MMM, YYYY @ h:00 a')
  const status = (() => {
    if (doc.received)
      return 'received'
    if (doc.rejected)
      return 'rejected'
    if (doc.notReceived)
      return 'not-received'
    if (doc.priority)
      return 'priority'
    if (!isSender)
      return
    return 'sent'
  })()
  const disabled = disabledProp || isSender || doc.received || doc.notReceived
  return (
    <>
      <FileDetailDialog
        doc={doc}
        pk={pk}
        open={showDetails}
        onClose={() => setShowDetails(false)}
        onFileAction={onFileAction}
      />
      <ButtonBase
        disabled={disabled}
        sx={hover ? selectedBox : unselectedBox}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setShowDetails(true)}
      >
        <Box sx={maxWidth}>
          <Grid container spacing={2} alignItems='center' wrap='nowrap'>
            <Grid item sx={flexGrow}>
              <Typography variant='h6'>{Array.isArray(doc.kind) ? doc.kind.join(', ') : doc.kind}</Typography>
              <Typography variant='body2' color='textSecondary'><FileItemUser user={isSender ? reciever : sender} isSender={!isSender} /></Typography>
              <Typography variant='body2' color='textSecondary'>Expires: {formattedDate}</Typography>
            </Grid>
            <Grid item sx={flexBasis}>
              <RequestStatus status={status} />
            </Grid>
          </Grid>
        </Box>
      </ButtonBase>
      <Divider />
    </>
  )
}

export default FileRequestLineItem
