import { Close } from "@mui/icons-material"
import { Card, CardContent, Grid, useMediaQuery, IconButton, Paper, Stack, Step, StepLabel, Stepper, Typography, Alert, AlertTitle, Button, Link } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useReducer } from "react"
import { useHistory} from "react-router"
import { Link as RouterLink } from 'react-router-dom'

import { paddingStyle } from '../dashboardStyles'
import fileComplete from "./fileComplete"

import Confirmation from "./Confirmation"
import SetRecipient from "./SetRecipient"
import UploadDocument from "./UploadDocument"
import DocumentDetails from "./DocumentDetails"
import AdditionalDocuments from "./AdditionalDocuments"
import Success from "./Success"
import useLoggedInUser from "hooks/useLoggedInUser"
import useOrganization from "hooks/useOrganization"
import { plans } from "utilities/getPlan"
import { Box } from "@mui/system"

const overviewStyle = {
  ...paddingStyle,
  maxWidth: '80vw',
  overflowX: 'auto',
  margin: 'auto',
}

const steps = [
  {
    label: 'Recipient',
    Content: SetRecipient,
  },
  {
    label: 'Upload Document',
    Content: UploadDocument,
  },
  {
    label: 'Document Details',
    Content: DocumentDetails,
  },
  {
    label: 'Additional Documents',
    Content: AdditionalDocuments,
  },
  {
    label: 'Confirmation',
    Content: Confirmation,
  },
  {
    label: 'Success',
    Content: Success,
  }
]

const docReducer = (state, action) => {
  switch(action.type) {
    case 'next':
      return {...state, activeStep: state.activeStep + 1, uploading: false }
    case 'prev':
      return { ...state, activeStep: state.activeStep - 1 }
    case 'changeRecipient':
      return { ...state, recipient: action.payload }
    case 'uploading':
      return { ...state, uploading: true }
    case 'encryptingFile':
      return { ...state, docs: state.docs.map((d, i) => {
        if (i !== action.payload)
          return d
        return {
          ...d,
          status: 'Encrypting',
        }
      })}
    case 'uploadingFile':
      return {
        ...state, docs: state.docs.map((d, i) => {
          if (i !== action.payload)
            return d
          return {
            ...d,
            status: 'Uploading',
            progress: action.progress,
          }
        })
      }
    case 'fileUploaded':
      return {
        ...state, docs: state.docs.map((d, i) => {
          if (i !== action.payload)
            return d
          return {
            ...d,
            status: 'Complete',
          }
        })
      }
    case 'updateFileDetails':
      return {...state, docs: state.docs.map((d, i) => {
        if (i !== state.currentEditing)
          return d
        return {
          ...d,
          [action.field]: action.value,
        }
      })}
    case 'removeFile':{
      const index = action.removeAtIndex ?? state.currentEditing
      const activeStep = (() => {
        let change = 0
        if (action.navigateNext === true)
          change = 1
        else if (action.navigateNext === false)
          change = -1
        return state.activeStep + change
      })()
      return { ...state, activeStep, docs: state.docs.filter((_, i) => i !== index) }
    }
    case 'editFile':
      return {...state, activeStep: 2, currentEditing: action.payload }
    case 'addAnotherFile':
      return { ...state, activeStep: 1, docs: state.docs.concat(action.copy ? { ...state.docs[state.docs.length - 1], copied: true, file: undefined } : {}), currentEditing: state.docs.length }
    case 'appendFile':{
      const newFile = {
        file: action.payload,
        kind: [],
        recordCount: '',
        priority: '',
        deadline: '',
      }
      const newState = { ...state, docs: [...state.docs] }
      if (newState.docs.length > 0 && (newState.docs[newState.docs.length - 1].copied || !fileComplete(newState.docs[newState.docs.length - 1]))) {
        const prevFile = {...state.docs[state.docs.length - 1], file: action.payload }
        const file = prevFile.copied ? prevFile : newFile
        newState.docs = state.docs.slice(0, state.docs.length - 1).concat(file)
      } else {
        newState.docs.push(newFile)
        newState.currentEditing = newState.docs.length - 1
      }
      return newState
    }
    default:
      throw new Error()
  }
}

const UpgradePlanLink = ({children}) => (
  <Link component={RouterLink} to='/dashboard/account?upgrade=1'>
    {children}
  </Link>
)

const NewDocument = () => {
  const [ docModel, docDispatch ] = useReducer(docReducer, {
    activeStep: 0,
    recipient: {},
    docs: [],
    currentEditing: '',
  })
  const history = useHistory()
  const Content = steps[docModel.activeStep].Content
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))

  const [loggedInUser] = useLoggedInUser()
  const [orgData] = useOrganization(loggedInUser.organization)
  const planIsFree = `${orgData?.subscription?.subscriptionPlanId ?? (orgData?.subscription?.pending ? plans.freemium : 'loading...')}` === plans.freemium
  const remainingSends = orgData?.subscription?.remainingSends ?? 0

  const disableSend = planIsFree && remainingSends <= 0
  return (
    <>
      <Card sx={{
        background: theme => theme.palette.grey[200],
      }}>
        <CardContent>
          {planIsFree && (
            <Alert severity={remainingSends > 0 ? 'warning' : 'error'}>
              <AlertTitle>{remainingSends} Remaining Documents</AlertTitle>
              {remainingSends > 0 ? (<>
                Just a reminder, you will not be able to send more than {remainingSends} documents out. But, you can always <UpgradePlanLink>upgrade your plan</UpgradePlanLink>!
              </>) : (<>
                Ah, shoot. Looks like you have used all of your complimentary sends. To continue sending documents, <UpgradePlanLink>upgrade your plan</UpgradePlanLink>.
              </>)}
            </Alert>
          )}
          {disableSend ? (
            <Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
              <Button component={RouterLink} to='/dashboard/account?upgrade=1'>
                Upgrade Your Plan
              </Button>
            </Box>
          ) : (
            <>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='h5'>Upload New Document</Typography>
                <IconButton color='secondary' onClick={history.goBack}>
                  <Close />
                </IconButton>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <Paper sx={overviewStyle}>
                    <Stepper
                      activeStep={docModel.activeStep}
                      orientation={isLargeScreen ? 'vertical' : 'horizontal'}
                    >
                      {steps.map(({ label }) => (
                        <Step key={label} disabled={disableSend}>
                          <StepLabel>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Content model={docModel} dispatch={docDispatch} onClose={history.goBack} disabled={disableSend} />
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    </>
  )
}

export default NewDocument
