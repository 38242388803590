import { Button, Grid } from "@mui/material"

const YesNoSelection = ({ onChange, value }) => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Button size='large' fullWidth onClick={() => onChange(true)} variant={value === true ? 'contained' : 'outlined'} color='primary'>yes</Button>
    </Grid>
    <Grid item xs={6}>
      <Button size='large' fullWidth onClick={() => onChange(false)} variant={value === false ? 'contained' : 'outlined'} color='primary'>no</Button>
    </Grid>
  </Grid>
)

export default YesNoSelection
