import { useReducer } from "react"

const useEditableReducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return {
        current: action.payload,
        original: action.payload,
      }
    case 'edit':
      return {
        ...state,
        current: {
          ...state.current,
          [action.field]: action.value,
        },
      }
    case 'cancel':
      return {
        ...state,
        current: state.original,
      }
    case 'save':
      return {
        ...state,
        original: state.current,
      }
    default:
      throw new Error()
  }
}

const useEditable = initialValue => {
  const [value, valueDispatch] = useReducer(useEditableReducer, {
    current: initialValue,
    original: initialValue,
  })
  return [value.current, valueDispatch]
}

export default useEditable
