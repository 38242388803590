import { Divider, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Tooltip } from "@mui/material"

const BaseListItem = ({children}) => (
  <>
    <ListItem>
      {children}
    </ListItem>
    <Divider />
  </>
)

const ButtonListItem = ({Icon, value, field, label, onClick, disabled}) => (
  <>
    <ListItemButton onClick={onClick} disabled={disabled}>
      <ListItemIcon>
        <Tooltip title={label}>
          <Icon color='secondary' />
        </Tooltip>
      </ListItemIcon>
      <ListItemText primary={value[field] ?? ''} />
    </ListItemButton>
  </>
)

const PresentListItem = ({Icon, value, field, label, editing}) => (
  <BaseListItem>
    <ListItemIcon>
      <Tooltip title={label}>
        <Icon color={editing ? undefined : 'secondary'} />
      </Tooltip>
    </ListItemIcon>
    <ListItemText primaryTypographyProps={{color: editing ? 'textSecondary' : undefined}} primary={value[field] ?? ''} />
  </BaseListItem>
)

const EditListItem = ({ Icon, value, field, label, onChange }) => (
  <BaseListItem>
    <TextField
      fullWidth
      value={value[field] ?? ''}
      name={field}
      placeholder={label}
      onChange={onChange}
      variant='standard'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Icon color='secondary' />
          </InputAdornment>
        ),
      }}
    />
  </BaseListItem>
)

const ListDetailViewItem = ({editing, disabled, onClick, ...rest}) => {
  if (onClick)
    return <ButtonListItem onClick={onClick} disabled={disabled} {...rest} />
  if (editing && !disabled)
    return <EditListItem {...rest} />
  return <PresentListItem editing={editing} {...rest} />
}

const ListDetailView = ({value = {}, fields = [], editing, onChange }) => {
  return (
    <List>
      {fields.map(field => (
        <ListDetailViewItem
          editing={editing}
          onChange={onChange}
          key={field.field}
          value={value}
          {...field}
        />
      ))}
    </List>
  )
}

export default ListDetailView
