import { useEffect } from "react"
import useBaseApi from "./useBaseApi"
import useEditable from "./useEditable"

const useLoggedInUser = () => {
  const [ user, dispatch ] = useEditable({})
  const api = useBaseApi()

  useEffect(() => {
    api.users().get()
      .then(user => {
        dispatch({
          type: 'set',
          payload: user,
        })
      })
  }, [api, dispatch])

  return [ user, dispatch ]
}

export default useLoggedInUser
