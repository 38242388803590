import { ArrowRight } from "@mui/icons-material"
import { Alert, Button, Card, CardContent, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { Box } from "@mui/system"
import useAuthorization from "hooks/useAuthorization"
import useBaseApi from "hooks/useBaseApi"
import { useCallback, useState } from "react"
import { plans } from "utilities/getPlan"

const CARD_HEIGHT = 306
const PlanCard = ({
  planId,
  currentPlan,
  title,
  subtitle,
  cost,
  features,
  submitText = 'Activate',
  onSubmit,
  disabled,
}) => {
  const onSubmitPlan = useCallback(() => onSubmit(planId), [ planId, onSubmit ])
  return (
    <Card raised>
      <CardContent>
        <Stack justifyContent='space-between' sx={{ minHeight: CARD_HEIGHT - 40 }}>
          <Box>
            <Typography variant='h6' align='center'>{title.toUpperCase()}</Typography>
            <Typography variant='body1' align='center' color='textSecondary'>{subtitle}</Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            {cost !== undefined ? (
              <Typography variant='h4' align='center'>${cost} <Typography component='span' variant='h6' color='textSecondary'>/ month</Typography></Typography>
            ) : null}
            <List dense>
              {features.map(f => (
                <ListItem key={f}>
                  <ListItemIcon>
                    <ArrowRight />
                  </ListItemIcon>
                  <ListItemText primary={f} />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box>
            {planId === currentPlan ? (
              <Alert severity='info'>
                Current Plan
              </Alert>
            ) : (
              <Button disabled={disabled} onClick={onSubmitPlan} fullWidth variant='contained'>
                {submitText}
              </Button>
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

function createNewSubscription(planId, organizationId, email, handleSuccess, handleError) {
  const checkoutDetails = {
    product: planId,
    passthrough: JSON.stringify({ organizationId }),
    email,
    successCallback: handleSuccess,
    closeCallback: handleError,
  }

  console.log(checkoutDetails)
  window.Paddle.Checkout.open(checkoutDetails)

}

const PlanSelection = ({ currentPlanId, paddleId, organizationId, email }) => {
  const api = useBaseApi()
  const [ loading, setLoading ] = useState(false)
  const { logout } = useAuthorization()
  const onPlanActivate = useCallback(async (planId) => {
    setLoading(true)
    const handleSuccess = d => {
      alert('Subscription Updated Successfully. You will be logged out for the changes to take effect')
      logout()
    }

    const handleError = d => {
      console.error('ERROR', d)
      setLoading(false)
    }
    if (!paddleId)
      createNewSubscription(planId, organizationId, email, handleSuccess, handleError)
    else
      api.organizations(organizationId).subscriptions().change({
        planId,
      })
        .then(handleSuccess)
        .catch(handleError)
  }, [ organizationId, paddleId, email, api, logout ])
  return (
    <>
      <Box sx={{ mt: 6, mb: 10 }}>
        <Typography variant='h4' align='center' sx={{ mb: 1 }}>Ready to take your relays to the next level?</Typography>
        <Typography variant='h6' align='center'>Find the plan that works best for you:</Typography>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={3}>
          <PlanCard
            disabled={loading}
            planId={plans.freemium}
            currentPlan={currentPlanId}
            onSubmit={onPlanActivate}
            title='Free'
            subtitle='You read that right'
            cost={0}
            features={[
              <>&infin; Recieves</>,
              <>&infin; Members</>,
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <PlanCard
            disabled={loading}
            planId={plans.basic}
            currentPlan={currentPlanId}
            onSubmit={onPlanActivate}
            title='Basic'
            subtitle='For small teams'
            cost={39}
            features={[
              'Free Features',
              '5 Sending Seats',
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <PlanCard
            disabled={loading}
            planId={plans.prestige}
            currentPlan={currentPlanId}
            onSubmit={onPlanActivate}
            title='Prestige'
            subtitle='For large teams'
            cost={75}
            features={[
              'Basic Features',
              '10 Sending Seats',
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <PlanCard
            currentPlanId={currentPlanId}
            planId='Enterprise'
            disabled={loading}
            onSubmit={() => alert('Please reach out to contact@relay-app.io')}
            title='Enterprise'
            subtitle='Made to order'
            features={[
              'SAML',
              'Branding',
              'And More'
            ]}
            submitText='Contact Us'
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PlanSelection
