import { Box, Container, Drawer, List, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, useMediaQuery, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import TopBar from '../components/TopBar'
import {Home, PermContactCalendar, Person, History, Menu} from '@mui/icons-material'
import {Link as RouterLink, Route, Switch, useRouteMatch, Redirect} from 'react-router-dom'
import DashHome from './DashHome'
import NewDocument from './NewDocument'
import ContactsPage from './ContactsPage'
import useBaseApi from 'hooks/useBaseApi'
import AccountPage from './AccountPage'
import Log from './Log'

import {useState} from 'react'
import useAuthorization from 'hooks/useAuthorization'

const drawerWidth = 240

const links = [
  {
    title: 'Home',
    Icon: Home,
    link: '/',
  },
  {
    title: 'Contacts',
    Icon: PermContactCalendar,
    link: '/contacts',
  },
  {
    title: 'Account',
    Icon: Person,
    link: '/account',
  },
  {
    title: 'Log',
    Icon: History,
    link: '/log',
  },
]

const whiteColorStyle = {
  color: 'white',
}

const selectedRow = {
  backgroundColor: 'rgba(255,255,255,0.2)',
}

const containerStyle = {
  overflowY: 'auto',
  paddingBottom: '8px',
}

const mainStyle = { flexGrow: 1, paddingTop: 3 }

const listContainerStyle = { overflow: 'auto' }

const drawerStyle = { width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: theme => theme.palette.primary.main } }

const primaryStyle = { display: 'flex' }

const Dashboard = () => {
  const slug = useRouteMatch('/dashboard/:slug')?.params?.slug ?? ''
  const api = useBaseApi()
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const [ showDrawer, setShowDrawer ] = useState(false)
  const {logout} = useAuthorization()
  if (!api.loggedIn())
    return <Redirect to='/' />
  return (
    <Box sx={primaryStyle}>
      <TopBar>
        <Box sx={{flex: 1}} />
        {isLargeScreen ? null : (
          <IconButton onClick={() => setShowDrawer(t => !t)}>
            <Menu />
          </IconButton>
        )}
        <Button onClick={logout}>
          Logout
        </Button>
      </TopBar>
      <Drawer
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        variant={isLargeScreen ? 'permanent' : 'temporary'}
        sx={drawerStyle}
      >
        <Toolbar />
        <Box sx={listContainerStyle}>
          <List>
            {links.map(({title, Icon, link}) => {
              const selected = slug === link.slice(1)
              return (
                <ListItem key={title} disablePadding sx={selected ? selectedRow : undefined}>
                  <ListItemButton selected={selected} component={RouterLink} to={`/dashboard${link}`}>
                    <ListItemIcon ><Icon sx={whiteColorStyle} /></ListItemIcon>
                    <ListItemText primary={title} sx={whiteColorStyle} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </Box>
      </Drawer>
      <Box component='main' sx={mainStyle}>
        <Toolbar />
        <Container sx={containerStyle}>
          <Switch>
            <Route path='/dashboard/contacts'>
              <ContactsPage />
            </Route>
            <Route path='/dashboard/account'>
              <AccountPage />
            </Route>
            <Route path='/dashboard/new-document'>
              <NewDocument />
            </Route>
            <Route path='/dashboard/log'>
              <Log />
            </Route>
            <Route path='/dashboard'>
              <DashHome />
            </Route>
          </Switch>
        </Container>
      </Box>
    </Box>
  )
}

export default Dashboard
