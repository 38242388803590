import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { decryptPrivateKey } from 'utilities/encrypter'

const Authorization = createContext({})

export const AuthorizationProvider = ({children}) => {
  const [ token, setToken ] = useState({})

  const fullToken = useMemo(() => ({
    expiresAt: token.expiresAt,
    token: token.token,
    secretKey: token.secretKey,
    logout: () => setToken({}),
    login: async ({ token, password, salt, encryptedPrivateJwk }) => {
      setToken({
        ...token,
        secretKey: await decryptPrivateKey({password, salt, encryptedPrivateJwk }),
      })
    },
  }), [ token, setToken ])

  useEffect(() => {
    const interval = setInterval(() => {
      if (new Date(fullToken.expiresAt) <= new Date()) {
        alert('Your session has expired. You will be logged out.')
        fullToken.logout()
      }
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [fullToken])

  return (
    <Authorization.Provider value={fullToken}>
      {children}
    </Authorization.Provider>
  )
}

export const useAuthorization = () => useContext(Authorization)
export default useAuthorization
