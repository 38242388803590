import { Close, InsertDriveFile } from "@mui/icons-material"
import { Grid, IconButton, Paper, Typography } from "@mui/material"
import { marginBottomStyle, paddingStyle } from '../dashboardStyles'

const paperStyle = paddingStyle

const FileOverview = ({ onRemoveClick, file, children }) => (
  <Paper sx={{ ...paperStyle, ...marginBottomStyle }}>
    <Grid container alignItems='center' spacing={2}>
      <Grid item><InsertDriveFile color='primary' /></Grid>
      <Grid item><Typography variant='body1' color='secondary'>{file.file.name}</Typography></Grid>
      <Grid item style={{ flex: 1 }}>{children}</Grid>
      <Grid item><IconButton color='secondary' onClick={onRemoveClick}><Close /></IconButton></Grid>
    </Grid>
  </Paper>
)

export default FileOverview
