import axios from 'axios'
import streamsaver from 'streamsaver'

export const methods = Object.freeze({
  read: 'get',
  create: 'post',
  update: 'patch',
  delete: 'delete',
})

export const makeRequester = (Authorization, apiBase) => (method, uri, config) => (data = {}, authOverride) => {
  const authHeader = authOverride ? authOverride : Authorization
  let requestPromise
  const path = `${apiBase}/${uri}`
  const headers = authHeader ?  { Authorization: authHeader } : { }
  switch (method) {
    case methods.read:
    case methods.delete:
      requestPromise = axios[method](path, {
        ...config,
        params: data,
        headers,
      })
      break
    default:
      requestPromise = axios[method](path, data, { ...config, headers })
      break
  }
  return requestPromise
    .then(({ data }) => data)
    .catch(e => e.response.data)
}

export const fileRequester = async ({url, transformer, fileName}) => {
  const fileStream = streamsaver.createWriteStream(fileName)
  const res = await fetch(url)
  const readStream = res.body
  const writer = fileStream.getWriter()
  const reader = readStream.getReader()
  let chunks = []
  let done = false
  let totalSize = 0
  while(!done) {
    const r = await reader.read()
    done = r.done
    if (done)
      continue
    chunks.push(r.value)
    totalSize += r.value.byteLength
  }
  let result = new Uint8Array(totalSize)
  let offset = 0
  for (const chunk of chunks) {
    result.set(chunk, offset)
    offset += chunk.byteLength
  }
  if (transformer)
    result = await transformer(result)
  await writer.write(result)
  await writer.close()
}
